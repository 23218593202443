import React, { useState, useEffect } from "react";
import { Row, Col, Form, Dropdown, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as NewEventStyles from "../styles/event";

function Event(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [times, setTimes] = useState({ startTimes: [], endTimes: [] });

    const [details, setDetails] = useState({
        title: "",
        titleError: false,
        description: "",
        date: moment().toDate(),
        allDay: false,
        repeat: false,
        repeatType: "option",
        startTime: "08:00",
        endTime: ""
    });

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
        navigate(-1);
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Calendar - New Event"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Calendar"));

        const startTimeSlots = [];
        let time = moment().startOf("day");
        const lastTime = moment().endOf("day");
        while (time.isBefore(lastTime)) {
            startTimeSlots.push(time.format("HH:mm"));
            time.add(30, "m");
        }

        const endTimeSlots = [];
        const startTime = moment(details.startTime, "HH:mm");
        startTime.add(30, "m");
        const endTime = moment(startTime);

        while (startTime.isBefore(lastTime)) {
            endTimeSlots.push(startTime.format("HH:mm"));
            startTime.add(30, "m");
        }

        setTimes({ startTimes: startTimeSlots, endTimes: endTimeSlots });

        setDetails((prevState) => {
            return { ...prevState, endTime: endTime.format("HH:mm") };
        });
        modifyLoad();
    }, []);

    function modifyLoad() {
        if (props.modify) {
            const data = { id: params.id };

            Axios.post("/pods/calendar/getSingleEvent", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        const event = data.event;
                        setDetails((prevState) => {
                            return {
                                ...prevState,
                                title: event.title,
                                date: moment(event.startDate, "DD/MM/YYYY").toDate(),
                                description: event.description,
                                allDay: event.timeType == "allDay",
                                startTime: event.timeType == "setTimes" && event.startTime,
                                endTime: event.timeType == "setTimes" && event.endTime,
                                repeat: event.repeatType != "single"
                            };
                        });

                        buildEndTimes(event.startTime);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleDateChange(date) {
        const values = details.values;

        values["date"] = date;

        setDetails((prevState) => {
            return { ...prevState, values: values };
        });
    }

    function handleCheck(event) {
        const { name, checked } = event.target;

        setDetails((prevState) => {
            return { ...prevState, [name]: checked };
        });
    }

    function handleChangeText(event) {
        const { name, value } = event.target;

        setDetails((prevState) => {
            return { ...prevState, [name]: value };
        });
    }

    function handleStartTimeSelect(time) {
        buildEndTimes(time);
        const endTime = moment(time, "HH:mm").add(30, "m");

        setDetails((prevState) => {
            return { ...prevState, startTime: time, endTime: endTime.format("HH:mm") };
        });
    }

    function buildEndTimes(time) {
        const endTime = moment(time, "HH:mm").add(30, "m");
        const lastTime = moment().endOf("day");
        const endTimeSlots = [];
        while (endTime.isBefore(lastTime)) {
            endTimeSlots.push(endTime.format("HH:mm"));
            endTime.add(30, "m");
        }

        setTimes((prevState) => {
            return { ...prevState, endTimes: endTimeSlots };
        });
    }

    function handleEndTimeSelect(time) {
        setDetails((prevState) => {
            return { ...prevState, endTime: time };
        });
    }

    function handleCreateEvent() {
        if (details.title == "") {
            setDetails((prevState) => {
                return { ...prevState, titleError: true };
            });
        } else {
            let timeType = "";
            if (details.allDay) {
                timeType = "allDay";
            } else {
                timeType = "setTimes";
            }

            const data = {
                title: details.title,
                description: details.description,
                startDate: details.date,
                timeType: timeType,
                startTime: details.allDay ? "---" : details.startTime,
                endTime: details.allDay ? "---" : details.endTime,
                repeatType: details.repeat ? details.repeatType : "single"
            };
            Axios.post("/pods/calendar/submitNewEvent", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal({ header: "Calendar", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleUpdateClick() {
        if (details.title == "") {
            setDetails((prevState) => {
                return { ...prevState, titleError: true };
            });
        } else {
            let timeType = "";
            if (details.allDay) {
                timeType = "allDay";
            } else {
                timeType = "setTimes";
            }

            const data = {
                id: params.id,
                title: details.title,
                description: details.description,
                startDate: details.date,
                timeType: timeType,
                startTime: details.allDay ? "---" : details.startTime,
                endTime: details.allDay ? "---" : details.endTime,
                repeatType: details.repeat ? details.repeatType : "single"
            };
            Axios.post("/pods/calendar/updateSingleEvent", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal({ header: "Calendar", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleTitleOnBlur() {
        if (details.title != "") {
            setDetails((prevState) => {
                return { ...prevState, titleError: false };
            });
        }
    }

    return (
        <div style={NewEventStyles.body}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label style={NewEventStyles.formTitles}>Title:</Form.Label>
                        <Form.Control
                            isInvalid={details.titleError}
                            type="text"
                            name="title"
                            value={details.title}
                            onChange={handleChangeText}
                            onBlur={handleTitleOnBlur}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a title</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label style={NewEventStyles.formTitles}>Description:</Form.Label>
                        <Form.Control as="textarea" rows={5} name="description" value={details.description} onChange={handleChangeText} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label style={NewEventStyles.formTitles}>Date:</Form.Label> <br />
                        <DatePicker dateFormat="dd/MM/yyyy" selected={details.date} onChange={(date: Date) => handleDateChange(date)} />
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label style={NewEventStyles.formTitles}>Time:</Form.Label> <br />
                        <Form.Check type="checkbox" label="All Day" name="allDay" checked={details.allDay} onChange={handleCheck} />
                    </Form.Group>
                    <Form.Group>
                        <div style={!details.allDay ? NewEventStyles.show : NewEventStyles.hidden}>
                            <Row>
                                <Col sm={2}>
                                    <Form.Label style={NewEventStyles.formDropdownTitle}>Start:</Form.Label>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle>{details.startTime}</Dropdown.Toggle>
                                        <Dropdown.Menu style={NewEventStyles.dropdownMenu}>
                                            {times.startTimes.map((time, index) => {
                                                return (
                                                    <Dropdown.Item key={index} onClick={handleStartTimeSelect.bind(this, time)}>
                                                        {time}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={NewEventStyles.formDropdownTitle}>End:</Form.Label>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle>{details.endTime}</Dropdown.Toggle>
                                        <Dropdown.Menu style={NewEventStyles.dropdownMenu}>
                                            {times.endTimes.map((time, index) => {
                                                return (
                                                    <Dropdown.Item key={index} onClick={handleEndTimeSelect.bind(this, time)}>
                                                        {time}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    </Form.Group>
                    <br />
                    <Form.Group style={NewEventStyles.hidden}>
                        <Form.Label style={NewEventStyles.formTitles}>Recurrence</Form.Label> <br />
                        <Form.Check type="checkbox" label="This event does repeats" name="repeat" checked={details.repeat} onChange={handleCheck} />
                    </Form.Group>
                    <br />
                    {props.modify ? <Button onClick={handleUpdateClick}>Update</Button> : <Button onClick={handleCreateEvent}>Create</Button>}
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Event;
