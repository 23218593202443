import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//STYLES
import * as AnimationsStyles from "../styles/animations";
import * as Colors from "../../../../globalStyles/environmentColors";

//COMPONENTS
import PageNavigation from "../navBarClickMethods";

function TopMenu(props) {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuHeight, setMenuHeight] = useState(0);
    const [bordRadius, setBordRadius] = useState("10px 10px 10px 10px");

    const [backColor, setBackColor] = useState("white");
    const [textColor, setTextColor] = useState(Colors.secondary);

    useEffect(() => {
        if (menuOpen) {
            setMenuHeight(120);
        } else {
            setMenuHeight(0);
        }
    }, [menuOpen]);

    function handleOnEnter() {
        setMenuOpen(true);
        setBordRadius("10px 10px 0 0");
        setBackColor(Colors.secondary);
        setTextColor("white");
    }

    function handleOnLeave() {
        setMenuOpen(false);
        setBordRadius("10px 10px 10px 10px");
        setBackColor("white");
        setTextColor(Colors.secondary);
    }

    return (
        <div>
            <div
                style={{ ...AnimationsStyles.menuButton, borderRadius: bordRadius, backgroundColor: backColor, color: textColor }}
                onMouseEnter={handleOnEnter}
                onMouseLeave={handleOnLeave}
            >
                {props.title}
            </div>
            <div style={{ ...AnimationsStyles.menu, height: `${menuHeight}px` }} onMouseEnter={handleOnEnter} onMouseLeave={handleOnLeave}>
                <MenuItem title="About" nav={PageNavigation.bind(this, `${props.title.toLowerCase()}-about`, navigate)} />
                <MenuItem title="Leaders" nav={PageNavigation.bind(this, `${props.title.toLowerCase()}-leaders`, navigate)} />
                <MenuItem title="Badges" nav={PageNavigation.bind(this, `${props.title.toLowerCase()}-badges`, navigate)} />
            </div>
        </div>
    );
}

function MenuItem(props) {
    const [backColor, setBackColor] = useState("white");
    const [textColor, setTextColor] = useState(Colors.secondary);

    function handleOnEnter() {
        setBackColor(Colors.secondary);
        setTextColor("white");
    }

    function handleOnLeave() {
        setBackColor("white");
        setTextColor(Colors.secondary);
    }

    return (
        <div
            style={{ ...AnimationsStyles.menuItem, backgroundColor: backColor, color: textColor }}
            onMouseEnter={handleOnEnter}
            onMouseLeave={handleOnLeave}
            onClick={props.nav}
        >
            {props.title}
        </div>
    );
}

export default TopMenu;
