import * as Colors from "../../../../globalStyles/environmentColors";

export const border = {
    paddingRight: "5px",
    paddingLeft: "5px",
    marginBottom: "5px"
};

export const eventBar = {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10px",
    cursor: "pointer"
};

export const allDayColors = {
    backgroundColor: Colors.secondary,
    color: "black"
};

export const setTimesColors = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const eventsBox = {
    height: "75px",
    overflowY: "auto"
};

export const bottomArrow = {
    textAlign: "center",
    color: "red"
};
