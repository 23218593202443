import React from "react";

//PODS
//--ADMINISTRATOR
import HVSAdminLoginControl from "./administration/HVSLoginForm/loginControl";

//--ADMIN
import CalendarAdmin from "./calendar/admin/podRouter";

//--FRONTEND
import CalendarFrontEnd from "./calendar/frontEnd/podRouter";

function EnvironmentPodsMap(props) {
    return (
        <div>
            {props.podCode == "loginForm" && <HVSAdminLoginControl />}
            {/* podCode > component */}

            {/* ADMIN */}
            {props.podCode == "CPOD_CALENDAR_ADMIN" && <CalendarAdmin />}

            {/* FRONTEND */}
            {props.podCode == "CPOD_CALENDAR_FREND" && <CalendarFrontEnd />}
        </div>
    );
}

export default EnvironmentPodsMap;
