import * as Colors from "../../globalStyles/environmentColors";

export const body = {
    minHeight: "100vh",
    backgroundColor: Colors.background,
    zIndex: "1"
};

export const rightEdgeTop = {
    minHeight: "20vh",
    backgroundImage: `linear-gradient(to left, #FFFFFF, ${Colors.primary}`,
    textAlign: "center",
    paddingTop: "5px"
};

export const rightEdgeBottom = {
    minHeight: "80vh",
    backgroundImage: `linear-gradient(to left, #FFFFFF, ${Colors.primary}`,
    textAlign: "center",
    paddingTop: "5px"
};

export const mainBody = {
    paddingTop: "20px",
    textAlign: "center"
};

export const welshFlagContainer = {
    paddingTop: "10px",
    paddingLeft: "0px"
};

export const welshFlagPic = {
    height: "180px",
    width: "200px"
};
