export const body = {
    paddingRight: "30px"
};

export const formTitles = {
    fontSize: "20px"
};

export const formDropdownTitle = {
    height: "50px",
    lineHeight: "50px",
    fontSize: "20px",
    margin: "auto",
    paddingTop: "auto",
    textAlign: "right"
};

export const dropdownMenu = {
    maxHeight: "200px",
    overflowY: "auto"
};

export const show = {
    visibility: "visible"
};

export const hidden = {
    visibility: "hidden"
};
