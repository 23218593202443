import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Switch from "react-switch";
import Axios from "axios";
import { useLocation } from "react-router-dom";

import UsersViewer from "./usersViewer";
import MoreSettings from "./moreSettings";

//ACTION FILES
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ContactsControlStyles from "../styles/contactsControl";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

function ContactsControl() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [showMainContact, setShowMainContact] = useState(false);
    const [mainContactUser, setMainContactUser] = useState({
        id: "",
        name: "",
        email: ""
    });
    const [otherContacts, setOtherContacts] = useState([]);

    const [userModal, setUserModal] = useState({
        open: false,
        mainContactMode: false,
        mainContactID: ""
    });

    function handleUserModalClose() {
        setUserModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [moreSettingsModal, setMoreSettingsModal] = useState({
        open: false,
        id: ""
    });

    function handleMoreSettingsClose() {
        setMoreSettingsModal((prevState) => {
            return { ...prevState, open: false };
        });
        openModal;
    }

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function openModal(header, message, open, error) {
        setModal((prevState) => {
            return {
                ...prevState,
                header: header,
                message: message,
                open: open,
                error: error
            };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Contacts"));
        dispatch(GS_navSettingsActions.UpdateSelected("Contacts"));

        getContactData();
    }, [location]);

    function getContactData() {
        Axios.post("/adminPods/contacts/getContactData")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setShowMainContact(data.mainContactPageStatus === "true");
                    setMainContactUser({
                        id: data.mainContactUser.id,
                        name: data.mainContactUser.fullName,
                        email: data.mainContactUser.email
                    });
                    setOtherContacts(data.otherContacts);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleMainContactShowChange(checked) {
        if (mainContactUser.name == "") {
            setModal({
                header: "Main Contact",
                message: "You cannot enable the Main Contact Page until you have added a user!",
                open: true
            });
        } else {
            setShowMainContact(checked);

            const data = { switchValue: checked.toString() };

            Axios.post("/adminPods/contacts/changeMainContactStatus", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error === "Yes") {
                        setModal({
                            header: "Contacts",
                            error: true,
                            message: data.message,
                            open: true
                        });
                        setShowMainContact(data.switchValue);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOpenContactModalInMainContactMode() {
        setUserModal((prevState) => {
            return {
                ...prevState,
                mainContactMode: true,
                mainContactID: mainContactUser.id,
                open: true
            };
        });
    }

    function handleOpenContactModal() {
        setUserModal((prevState) => {
            return {
                ...prevState,
                mainContactMode: false,
                open: true
            };
        });
    }

    //USER MODAL

    function UpdateMainContactUser(id, name, email) {
        setMainContactUser({
            id: id,
            name: name,
            email: email
        });
        handleUserModalClose();
    }

    //REMOVE MAIN CONTACT METHODS STAYING HERE AS THE MODELYN IS HERE!
    function handleRemoveMainContact() {
        if (showMainContact) {
            setModalYN({
                heading: "Main Contact",
                message:
                    "The Main Contact Page is active, removing the user from this form will disable the Contact Page. Are you sure you want to delete the user?",
                showAccept: true,
                acceptName: "Yes",
                acceptFunction: acceptRemoveMainContact,
                showCancel: true,
                cancelName: "No",
                open: true
            });
        } else {
            acceptRemoveMainContact();
        }
    }

    function acceptRemoveMainContact() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
        setUserModal((prevState) => {
            return { ...prevState, open: false };
        });

        Axios.post("/adminPods/contacts/removeMainContact")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setShowMainContact(data.mainContactPageStatus === "true");
                    setMainContactUser({
                        id: data.mainContactUser.id,
                        name: data.mainContactUser.fullName,
                        email: data.mainContactUser.email
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOpenMoreSettings(id) {
        setMoreSettingsModal((prevState) => {
            return { ...prevState, id: id, open: true };
        });
    }

    function handleOtherContactDelete(id) {
        setModalYN({
            heading: "Main Contact",
            message: "Are you sure you want to delete this contact form?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: acceptDeleteOtherContact.bind(this, id),
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function acceptDeleteOtherContact(id) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { id: id };

        Axios.post("/adminPods/contacts/removeOtherContact", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    getContactData();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ContactsControlStyles.body}>
            <h1>Main Contact</h1>
            <br />
            <Card style={ContactsControlStyles.cardStyle}>
                <Card.Body>
                    <Row>
                        <Col md={5} style={ContactsControlStyles.mainContactLabels}>
                            User: {mainContactUser.name}
                        </Col>
                        <Col md={5} style={ContactsControlStyles.mainContactLabels}>
                            Email: {mainContactUser.email}
                        </Col>
                        <Col md={1} style={ContactsControlStyles.mainContactButton}>
                            {mainContactUser.name == "" ? (
                                <div>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Add Receiver</Tooltip>}>
                                        <Button variant="success" onClick={handleOpenContactModalInMainContactMode}>
                                            <i className="fa-solid fa-address-book"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            ) : (
                                <div>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit Receiver</Tooltip>}>
                                        <Button variant="danger" onClick={handleOpenContactModalInMainContactMode}>
                                            <i className="fa-solid fa-address-book"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            )}
                        </Col>
                        <Col md={1} style={ContactsControlStyles.mainContactButton}>
                            {mainContactUser.name != "" && (
                                <div>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>More Settings</Tooltip>}>
                                        <Button variant="warning" onClick={handleOpenMoreSettings.bind(this, mainContactUser.id)}>
                                            <i className="fa-solid fa-user-gear"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
            <div style={ContactsControlStyles.mainContactSwitchText}>
                Show Main Contact Form:
                <Switch
                    className="customCSS-Content-New-Item-Switch"
                    onChange={handleMainContactShowChange}
                    checked={showMainContact}
                    checkedIcon={false}
                    uncheckedIcon={false}
                />
            </div>
            <br />
            <br />
            <h1>Other Contacts</h1>
            <Row>
                <Col style={ContactsControlStyles.otherContactsAddBtn}>
                    <Button onClick={handleOpenContactModal}>Add Contact</Button>
                </Col>
            </Row>
            {otherContacts.map((item, index) => {
                return (
                    <div key={index}>
                        <Card style={ContactsControlStyles.cardStyle}>
                            <Card.Body>
                                <Row>
                                    <Col md={5} style={ContactsControlStyles.mainContactLabels}>
                                        Name: {item.fullName}
                                    </Col>
                                    <Col md={5} style={ContactsControlStyles.mainContactLabels}>
                                        Email: {item.email}
                                    </Col>
                                    <Col md={1}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>More Settings</Tooltip>}>
                                            <Button variant="warning" onClick={handleOpenMoreSettings.bind(this, item.id)}>
                                                <i className="fa-solid fa-user-gear"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col md={1}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete Contact</Tooltip>}>
                                            <Button variant="danger" onClick={handleOtherContactDelete.bind(this, item.formID)}>
                                                <i className="fa-solid fa-trash-can"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}

            {/* USER VIEWER MODAL */}
            <Modal show={userModal.open} onHide={handleUserModalClose}>
                <UsersViewer
                    closeBtn={handleUserModalClose}
                    updateMainContact={UpdateMainContactUser}
                    mainContactMode={userModal.mainContactMode}
                    mainContactID={userModal.mainContactID}
                    removeMainContact={handleRemoveMainContact}
                    updateContacts={getContactData}
                    openModal={openModal}
                />
            </Modal>
            {/* MORE SETTINGS MODAL */}
            <Modal show={moreSettingsModal.open} onHide={handleMoreSettingsClose}>
                <MoreSettings userID={moreSettingsModal.id} closeBtn={handleMoreSettingsClose} />
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ContactsControl;
