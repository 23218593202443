import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import Axios from "axios";

//STYLES
import * as CalendarViewStyles from "../styles/calendarView";

//COMPONENTS
import DayView from "./dayView";

function CalendarView() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [month, setMonth] = useState({
        monthText: "",
        days: [],
        currentDate: ""
    });

    useEffect(() => {
        openMonth(moment());
    }, []);

    function openMonth(date) {
        const data = { monthIndex: `${moment(date).format("MM")}-${moment(date).format("YY")}` };
        Axios.post("/pods/events/getMonthBookings", data)
            .then((res) => {
                const data = res.data;

                //Calendar Process
                let monthText = "";
                if (moment(date).format("YYYY") != moment().format("YYYY")) {
                    monthText = `${moment(date).format("MMMM")} ${moment(date).format("YYYY")}`;
                } else {
                    monthText = moment(date).format("MMMM");
                }
                const startOfMonth = moment(date).startOf("month");
                let startDate = startOfMonth.startOf("week");
                const endOfMonth = moment(date).endOf("month");
                const finishDate = endOfMonth.endOf("week");

                let days = [];
                let week = [];
                let dayOfWeek = 0;

                while (startDate.isSameOrBefore(finishDate)) {
                    if (startDate.format("MMMM") == moment(date).format("MMMM")) {
                        week.push({
                            date: startDate.format("D"),
                            events: data.bookings.filter(
                                (event) => event.dayIndex == `${moment(startDate).format("DD")}-${moment(startDate).format("MM")}`
                            )
                        });
                    } else {
                        week.push("--");
                    }

                    dayOfWeek++;
                    if (dayOfWeek === 7) {
                        days.push(week);
                        week = [];
                        dayOfWeek = 0;
                    }

                    startDate.add(1, "d");
                }

                // console.log(days);
                setMonth({ days: days, monthText: monthText, currentDate: moment(date).startOf("month") });
                setIsLoaded(true);
            })
            .catch((err) => console.log(err));
    }

    function handleAdvanceOneMonth() {
        setIsLoaded(false);
        const date = moment(month.currentDate).add(1, "M");
        openMonth(date);
    }

    function handleGoBackMonth() {
        setIsLoaded(false);
        const date = moment(month.currentDate).subtract(1, "M");
        openMonth(date);
    }

    return (
        <div style={CalendarViewStyles.body}>
            <Row>
                <Col sm={1}></Col>
                <Col sm={10}>
                    <Row>
                        <Col></Col>
                        <Col style={CalendarViewStyles.monthTitle}>
                            <h1>{month.monthText}</h1>
                        </Col>
                        <Col style={CalendarViewStyles.navButtons}>
                            <Button onClick={handleGoBackMonth}>
                                <i className="fa-solid fa-angle-left"></i>
                            </Button>
                            <Button onClick={handleAdvanceOneMonth}>
                                <i className="fa-solid fa-angle-right"></i>
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <div>
                        <Row>
                            <Col style={CalendarViewStyles.topFirstDay}>Sunday</Col>
                            <Col style={CalendarViewStyles.topDay}>Monday</Col>
                            <Col style={CalendarViewStyles.topDay}>Tuesday</Col>
                            <Col style={CalendarViewStyles.topDay}>Wednesday</Col>
                            <Col style={CalendarViewStyles.topDay}>Thursday</Col>
                            <Col style={CalendarViewStyles.topDay}>Friday</Col>
                            <Col style={CalendarViewStyles.topDay}>Saturday</Col>
                        </Row>
                        {month.days.length > 0 && (
                            <div>
                                {month.days.map((week, wIndex) => {
                                    return (
                                        <Row key={wIndex}>
                                            {week.map((day, dIndex) => {
                                                if (dIndex == 0) {
                                                    return (
                                                        <Col key={dIndex} style={CalendarViewStyles.daysFristBorder}>
                                                            {isLoaded && day != "--" && <DayView day={day} />}
                                                        </Col>
                                                    );
                                                } else {
                                                    return (
                                                        <Col key={dIndex} style={CalendarViewStyles.daysBorder}>
                                                            {isLoaded && day != "--" && <DayView day={day} />}
                                                        </Col>
                                                    );
                                                }
                                            })}
                                        </Row>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </Col>
                <Col sm={1}></Col>
            </Row>
        </div>
    );
}

export default CalendarView;
