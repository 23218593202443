import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

//ACTIONS
import * as UserActions from "../../store/actions/user";
import * as GS_navSettingsActions from "../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as AdminHomeStyles from "./styles/admin_home";

//COMPONENTS
import Admin_NavBar from "../pods/navBar/navBar";

import Admin_NoneItems from "../pods/navBar/noneItems";

import Admin_Overview from "../pods/overview/overview";
import Admin_Users from "../pods/users/podRouter";
import Admin_Editorials from "../pods/editorials/podRouter";
import Admin_Media from "../pods/media/media";
import Admin_Contacts from "../pods/contacts/files/contactsControl";
import Admin_Pages from "../pods/pages/podRouter";
import Admin_Features from "../pods/features/podRouter";

function AdminHome(props) {
    const GS_navSettings = useSelector((state) => state.GS_navSettings);
    const user = useSelector((state) => state.user);
    const [overlay, setOverlay] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuItems = {
        overview: {
            id: 0,
            title: "Overview",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "overview")
        },
        users: {
            id: 1,
            title: "Users",
            subMenu: true,
            subMenuType: "component",
            subMenuItems: [
                {
                    id: 0,
                    title: "General Settings",
                    methodId: "generalsettings"
                }
            ]
        },
        pages: {
            id: 2,
            title: "Pages",
            subMenu: true,
            subMenuItems: []
        },
        editorials: {
            id: 3,
            title: "Editorials",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "editorials")
        },
        media: {
            id: 4,
            title: "Media",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "media")
        },
        contacts: {
            id: 5,
            title: "Contacts",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "contacts")
        },
        features: {
            id: 6,
            title: "Features",
            subMenu: true,
            subMenuItems: []
        },
        logOut: {
            id: 7,
            title: "Log Out",
            subMenu: false,
            subMenuItems: [],
            method: handleLogOut
        }
    };

    const [mainMenuItems, setMenuItems] = useState([]);

    useEffect(() => {
        onOpen();
        // eslint-disable-next-line
    }, []);

    function onOpen() {
        if (!user.auth) {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else if (user.type === "registered") {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else {
            Axios.post("/adminPods/admin/getPagesAndFeatures")
                .then((res) => {
                    const data = res.data;
                    if (data.error === "null") {
                        let pagesItem = { ...menuItems["pages"] };
                        let featuresItem = { ...menuItems["features"] };

                        if (data.pages.error != "no pages") {
                            if (data.pages.type == "sideMenu") {
                                console.log("sm");
                                const subMenu = [];
                                data.pages.items.map((page) => {
                                    const subItem = {
                                        id: page.uuid,
                                        title: page.title,
                                        methodId: page.uuid
                                    };
                                    subMenu.push(subItem);
                                });

                                pagesItem.subMenuItems = subMenu;
                                pagesItem.subMenuType = "sideMenu";
                            } else {
                                pagesItem.subMenuItems = data.pages.items;
                                pagesItem.subMenuType = "display";
                            }
                        } else {
                            pagesItem.subMenuType = "none";
                        }
                        if (data.features.error != "no features") {
                            if (data.features.type == "sideMenu") {
                                const subMenu = [];
                                data.features.items.map((feature) => {
                                    const subItem = {
                                        id: feature.uuid,
                                        title: feature.title,
                                        methodId: feature.adminCode
                                    };
                                    subMenu.push(subItem);
                                });

                                featuresItem.subMenuItems = subMenu;
                                featuresItem.subMenuType = "sideMenu";
                            } else {
                                featuresItem.subMenuItems = data.features.items;
                                featuresItem.subMenuType = "display";
                            }
                        } else {
                            featuresItem.subMenuType = "none";
                        }
                        BuildAdminMenu(pagesItem, featuresItem);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function BuildAdminMenu(pagesItem, featuresItem) {
        let items = [];

        if (user.type == "seniorAdmin" || user.type == "admin") {
            items = ["overview", "users", "pages", "editorials", "media", "contacts", "features", "logOut"];
        }
        if (user.type == "modifier") {
            items = ["overview", "pages", "editorials", "media", "logOut"];
        }
        const tempMenu = [];

        items.forEach((item) => {
            if (item === "pages") {
                pagesItem;
                tempMenu.push(pagesItem);
            } else if (item === "features") {
                tempMenu.push(featuresItem);
            } else {
                tempMenu.push(menuItems[item]);
            }
        });
        setMenuItems(tempMenu);
        setIsLoaded(true);
    }

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    //MENU CHANGE METHODS
    function subMenuSelect(subMenuType, title) {
        if (subMenuType == "none") {
            navigate(`./none/${title}`);
        }
        if (subMenuType == "component") {
            if (overlay) {
                setOverlay(false);
                dispatch(GS_navSettingsActions.ResetPrevSelected());
            }
            navigate(`./${title}`);
        }
        if (subMenuType == "sideMenu") {
            dispatch(GS_navSettingsActions.UpdateSelectedToPrev());
            dispatch(GS_navSettingsActions.UpdateSelected(CapsFirstLetter(title)));
            setOverlay(true);
        }
        if (subMenuType == "display") {
            if (overlay) {
                setOverlay(false);
                dispatch(GS_navSettingsActions.ResetPrevSelected());
            }
            navigate(`./${title}/list`);
        }
    }

    function changeSection(title) {
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        navigate(`./${title}`);
    }

    function openSubMenuItem(menu, code) {
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        if (menu == "pages") {
            navigate(`./pages/settings/${code}`);
        } else if (menu == "features") {
            navigate(`./features/pod/${code}`);
        } else {
            const link = `${menu}/${code.replace(" ", "")}`;
            navigate(`./${link}`);
        }
    }

    //MAIN MENU ACTIONS
    function handleLogOut() {
        console.log("logging out");
        Axios.post("/userLogin/admin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "Admin logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));
                    navigate(`/admin-${data.adminCode}/security/login`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleTurnOffOverlay() {
        dispatch(GS_navSettingsActions.UpdatePrevToSelected());
        setOverlay(false);
    }

    return (
        <div style={AdminHomeStyles.body}>
            {isLoaded && (
                <div>
                    <Row>
                        <Col md={GS_navSettings.size}>
                            <Row>
                                <Col style={AdminHomeStyles.title}>{GS_navSettings.size == 3 ? "Administration" : "Admin"}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Admin_NavBar
                                        size={GS_navSettings.size}
                                        items={mainMenuItems}
                                        changeSection={changeSection}
                                        subMenuSelect={subMenuSelect}
                                        openSubItem={openSubMenuItem}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12 - GS_navSettings.size} style={AdminHomeStyles.bodyColumn}>
                            <div style={AdminHomeStyles.mainBodyContainer}>
                                <div style={AdminHomeStyles.mainBody}>
                                    <Row>
                                        <Col style={AdminHomeStyles.sectionTitle}>{GS_navSettings.title}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Routes>
                                                <Route path="/overview" element={<Admin_Overview />} />
                                                <Route path="/users/*" exact element={<Admin_Users />} />
                                                <Route path="/editorials/*" element={<Admin_Editorials />} />
                                                <Route path="/media" element={<Admin_Media />} />
                                                <Route path="/contacts" element={<Admin_Contacts />} />
                                                <Route path="/none/:menu" element={<Admin_NoneItems />} />
                                                <Route
                                                    path="/pages/*"
                                                    element={
                                                        <Admin_Pages items={mainMenuItems.find((section) => section.title == "Pages").subMenuItems} />
                                                    }
                                                />
                                                <Route
                                                    path="/features/*"
                                                    element={
                                                        <Admin_Features
                                                            items={mainMenuItems.find((section) => section.title == "Features").subMenuItems}
                                                        />
                                                    }
                                                />
                                            </Routes>
                                        </Col>
                                    </Row>
                                </div>
                                {overlay && (
                                    <div style={AdminHomeStyles.overlay} onClick={handleTurnOffOverlay}>
                                        <span style={AdminHomeStyles.overlayText}>Click to go back to this section</span>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default AdminHome;
