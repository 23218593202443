export const GS_UPDATE_NAV_SIZING = "GS_UPDATE_NAV_SIZING";
export const GS_UPDATE_NAV_TITLE = "GS_UPDATE_NAV_TITLE";
export const GS_UPDATE_NAV_SELECTEDITEM = "GS_UPDATE_NAV_SELECTEDITEM";
export const GS_UPDATE_NAV_SELECTEDSUBITEM = "GS_UPDATE_NAV_SELECTEDSUBITEM";
export const GS_UPDATE_NAV_SELECTEDTOPREV = "GS_UPDATE_NAV_SELECTEDTOPREV";
export const GS_UPDATE_NAV_PREVTOSELECTED = "GS_UPDATE_NAV_PREVTOSELECTED";
export const GS_UPDATE_NAV_RESETPREVSELECTED = "GS_UPDATE_NAV_RESETPREVSELECTED";

export const UpdateSizing = (val) => {
    return {
        type: GS_UPDATE_NAV_SIZING,
        value: val
    };
};

export const UpdateTitle = (val) => {
    return {
        type: GS_UPDATE_NAV_TITLE,
        value: val
    };
};

export const UpdateSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_SELECTEDITEM,
        value: val
    };
};

export const UpdateSubSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_SELECTEDSUBITEM,
        value: val
    };
};

export const UpdateSelectedToPrev = (val) => {
    return {
        type: GS_UPDATE_NAV_SELECTEDTOPREV
    };
};

export const UpdatePrevToSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_PREVTOSELECTED
    };
};

export const ResetPrevSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_RESETPREVSELECTED
    };
};
