import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

//STYLES
import * as navBarStyles from "./styles/navBar";

//NAV METHODS
import pageNavigation from "./navBarClickMethods";

//COMPONENTS
import TopMenu from "./animations/topMenu";
import SideMenu from "./animations/sideMenu";

//IMAGES
import Logo from "../../../../public/images/Scout-Logo.png";

function BrowserNavBar() {
    const [servicesSubMenu, setServicesSubMenu] = useState(false);

    const GS_generalSettings = useSelector((state) => state.GS_generalSettings);

    const navigate = useNavigate();

    function ServicesSubMenuShow() {
        setServicesSubMenu(true);
    }
    function ServicesSubMenuHidden() {
        setServicesSubMenu(false);
    }

    return (
        <div className="navBar">
            <Row>
                <Col sm={2} className="navBarCorner"></Col>
                <Col style={{ padding: "0px" }}>
                    <div className="navBarTop">
                        <Image className="navLogo" src={Logo} />
                        <div className="topButtons">
                            <Row>
                                <Col>
                                    <TopMenu title="Beavers" />
                                </Col>
                                <Col>
                                    <TopMenu title="Cubs" />
                                </Col>
                                <Col>
                                    <TopMenu title="Scouts" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ padding: "0px" }}>
                    <div className="navBarSide">
                        <div className="sideMenu">
                            <SideMenu />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default BrowserNavBar;
