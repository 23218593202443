import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as SubMenuItemsDisplayStyles from "./styles/subMenuListDisplay";

function SubMenuItemsDisplay(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle(CapsFirstLetter(props.section)));
        dispatch(GS_navSettingsActions.UpdateSelected(CapsFirstLetter(props.section)));
    }, [location]);

    function handleOpenItem(item) {
        if (props.section == "pages") {
            navigate(`../settings/${item.uuid}`);
        } else if (props.section == "features") {
            navigate(`../pod/${item.adminCode}`);
        }
    }

    return (
        <div style={SubMenuItemsDisplayStyles.body}>
            <div style={SubMenuItemsDisplayStyles.container}>
                {props.items.map((item, index) => {
                    return (
                        <div key={index}>
                            <Card style={SubMenuItemsDisplayStyles.cardStyle} onClick={handleOpenItem.bind(this, item)}>
                                <Card.Body>
                                    <Row>
                                        <Col>{item.title}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <br />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SubMenuItemsDisplay;
