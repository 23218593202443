export const body = {
    paddingTop: "20px",
    minHeight: "700px"
};

export const topFirstDay = {
    border: "1px black solid",
    fontWeight: "bold",
    textAlign: "center"
};

export const topDay = {
    borderTop: "1px black solid",
    borderBottom: "1px black solid",
    borderRight: "1px black solid",
    fontWeight: "bold",
    textAlign: "center"
};

export const monthTitle = {
    textAlign: "center"
};

export const daysFristBorder = {
    borderBottom: "1px black solid",
    borderRight: "1px black solid",
    borderLeft: "1px black solid",
    height: "120px"
};

export const daysBorder = {
    borderBottom: "1px black solid",
    borderRight: "1px black solid",
    height: "120px"
};

export const navButtons = {
    textAlign: "right"
};
