import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//STYLES
import * as AnimationsStyles from "../styles/animations";
import * as Colors from "../../../../globalStyles/environmentColors";

//COMPONENTS
import PageNavigation from "../navBarClickMethods";

function SideMenu() {
    const navigate = useNavigate();

    const GS_generalSettings = useSelector((state) => state.GS_generalSettings);

    return (
        <div style={AnimationsStyles.sideMenu}>
            <MenuItem title="Home" nav={PageNavigation.bind(this, "/", navigate)} />
            <MenuItem title="Fund Raising" nav={PageNavigation.bind(this, "fund-raising", navigate)} />
            <MenuItem title="About Us" nav={PageNavigation.bind(this, "about-us", navigate)} />
            <MenuItem title="Events" nav={PageNavigation.bind(this, "events", navigate)} />
            <MenuItem title="Image Gallery" nav={PageNavigation.bind(this, "image-gallery", navigate)} />
            <MenuItem title="Hut Availability" nav={PageNavigation.bind(this, "hut-availability", navigate)} />
            <MenuItem title="Downloads" nav={PageNavigation.bind(this, "downloads", navigate)} />
            <MenuItem title="Trevor" nav={PageNavigation.bind(this, "trevor", navigate)} />
            {GS_generalSettings.mainContactPageVisible && <MenuItem title="Contact Us" nav={PageNavigation.bind(this, "contact-us", navigate)} />}
        </div>
    );
}

function MenuItem(props) {
    const [width, setWidth] = useState(150);
    const [backColor, setBackColor] = useState("white");
    const [textColor, setTextColor] = useState(Colors.secondary);

    function handleOnEnter() {
        setWidth(170);
        setBackColor(Colors.secondary);
        setTextColor("white");
    }

    function handleOnLeave() {
        setWidth(150);
        setBackColor("white");
        setTextColor(Colors.secondary);
    }

    return (
        <div
            style={{ ...AnimationsStyles.sideMenuItem, width: `${width}px`, backgroundColor: backColor, color: textColor }}
            onMouseEnter={handleOnEnter}
            onMouseLeave={handleOnLeave}
            onClick={props.nav}
        >
            {props.title}
        </div>
    );
}

export default SideMenu;
