import * as ReducerActions from "../../actions/globalSettings/GS_navSettings";

const initialState = {
    size: "3",
    title: "",
    selectedItem: "",
    selectedSubItem: "",
    prevSelectedItem: "",
    prevSelectedSubItem: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.GS_UPDATE_NAV_SIZING:
            return { ...state, size: action.value };
        case ReducerActions.GS_UPDATE_NAV_TITLE:
            return { ...state, title: action.value };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDITEM:
            return { ...state, selectedItem: action.value, selectedSubItem: "" };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDSUBITEM:
            return { ...state, selectedSubItem: action.value };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDTOPREV:
            return { ...state, prevSelectedItem: state.selectedItem, prevSelectedSubItem: state.selectedSubItem };
        case ReducerActions.GS_UPDATE_NAV_PREVTOSELECTED:
            return { ...state, selectedItem: state.prevSelectedItem, selectedSubItem: state.prevSelectedSubItem };
        case ReducerActions.GS_UPDATE_NAV_RESETPREVSELECTED:
            return { ...state, prevSelectedItem: "", prevSelectedSubItem: "" };
        default:
            return state;
    }
};

export default reducer;
