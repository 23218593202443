import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as DayViewStyles from "../styles/dayView";

//COMPONENTS
import DetailsView from "./detailsView";

function DayView(props) {
    const eventsView = useRef(null);
    const [events, setEvents] = useState({
        allDay: [0],
        setTimes: [0]
    });
    const [arrow, setArrow] = useState(false);

    const [detailsModal, setDetailsModal] = useState({
        open: false,
        event: []
    });

    function handleCloseDetailsModal() {
        setDetailsModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        if (props.day.events.length > 0) {
            const setTimes = props.day.events.filter((event) => event.timeType == "setTimes");

            setTimes.sort((a, b) => {
                if (a.startTime < b.startTime) return -1;
                if (a.startTime > b.startTime) return 1;
                return 0;
            });

            setEvents({ allDay: props.day.events.filter((event) => event.timeType == "allDay"), setTimes: setTimes });
        } else {
            setEvents({ allDay: [], setTimes: [] });
        }
    }, []);

    useEffect(() => {
        if (eventsView.current.scrollHeight > eventsView.current.clientHeight) {
            setArrow(true);
        }
    }, [events]);

    function handleOpenDetailsView(id) {
        const data = { id: id };

        Axios.post("/pods/events/getSingleEvent", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDetailsModal((prevState) => {
                        return { ...prevState, open: true, event: data.event };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Row>
                <Col>{props.day.date}</Col>
            </Row>
            <Row>
                <Col>
                    <div ref={eventsView} style={DayViewStyles.eventsBox}>
                        {events.allDay.map((event, index) => {
                            return (
                                <div key={index}>
                                    <Row style={DayViewStyles.border} onClick={handleOpenDetailsView.bind(this, event.uuid)}>
                                        <Col style={{ ...DayViewStyles.eventBar, ...DayViewStyles.allDayColors }}>{event.title}</Col>
                                    </Row>
                                </div>
                            );
                        })}
                        {events.setTimes.map((event, index) => {
                            return (
                                <div key={index}>
                                    <Row style={DayViewStyles.border} onClick={handleOpenDetailsView.bind(this, event.uuid)}>
                                        <Col
                                            style={{ ...DayViewStyles.eventBar, ...DayViewStyles.setTimesColors }}
                                        >{`${event.startTime}: ${event.title}`}</Col>
                                    </Row>
                                </div>
                            );
                        })}
                        {/* {props.day.events.map((event, index) => {
                            return (
                                <div key={index}>
                                    <Row style={DayViewStyles.border}>
                                        <Col style={DayViewStyles.backColor}>{event.title}</Col>
                                    </Row>
                                </div>
                            ); */}
                        {/* })} */}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={DayViewStyles.bottomArrow}>{arrow && <i className="fa-solid fa-sort-down"></i>}</div>
                </Col>
            </Row>
            <DetailsView open={detailsModal.open} close={handleCloseDetailsModal} event={detailsModal.event} />
        </div>
    );
}

export default DayView;
