import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

function Controller() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Calendar"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Calendar"));
        getEvents();
    }, []);

    function getEvents() {
        Axios.post("/pods/calendar/getEvents")
            .then((res) => {
                const data = res.data;
                setEvents(data.events);
            })
            .catch((err) => console.log(err));
    }

    function handleNewEventClick() {
        navigate("./newEvent");
    }
    function handleEventClick(id) {
        navigate(`./modifyEvent/${id}`);
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col style={ControllerStyles.newEventBtn}>
                    <Button onClick={handleNewEventClick}>New Event</Button>
                </Col>
            </Row>
            <Row style={ControllerStyles.topRow}>
                <Col>
                    <Card style={ControllerStyles.cardBody}>
                        <Card.Body>
                            <Row>
                                <Col>Title</Col>
                                <Col>Start Date</Col>
                                <Col>Start Time</Col>
                                <Col>End Time</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div style={ControllerStyles.eventsBox}>
                        {events.map((event, index) => {
                            return (
                                <div key={index}>
                                    <Card style={ControllerStyles.cardBody} onClick={handleEventClick.bind(this, event.uuid)}>
                                        <Card.Body>
                                            <Row>
                                                <Col>{event.title}</Col>
                                                <Col>{event.startDate}</Col>
                                                <Col>{event.timeType == "allDay" ? "All Day" : event.startTime}</Col>
                                                <Col>{event.timeType != "allDay" && event.endTime}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </div>
                            );
                        })}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Controller;
