export default function pageNavigation(pageTitle, navigate) {
    if (pageTitle == "/") {
        navigate("/");
    }
    if (pageTitle == "fund-raising") {
        navigate("/fund-raising");
    }
    if (pageTitle == "about-us") {
        navigate("/about-us");
    }
    if (pageTitle == "events") {
        navigate("/events");
    }
    if (pageTitle == "image-gallery") {
        navigate("/image-gallery");
    }
    if (pageTitle == "hut-availability") {
        navigate("hut-availability");
    }
    if (pageTitle == "downloads") {
        navigate("/downloads");
    }
    if (pageTitle == "trevor") {
        navigate("/trevor");
    }
    if (pageTitle == "contact-us") {
        navigate("/contact-us");
    }
    if (pageTitle == "beavers-about") {
        navigate("/beavers-about");
    }
    if (pageTitle == "beavers-leaders") {
        navigate("/beavers-leaders");
    }
    if (pageTitle == "beavers-badges") {
        navigate("/beavers-badges");
    }
    if (pageTitle == "cubs-about") {
        navigate("/cubs-about");
    }
    if (pageTitle == "cubs-leaders") {
        navigate("/cubs-leaders");
    }
    if (pageTitle == "cubs-badges") {
        navigate("/cubs-badges");
    }
    if (pageTitle == "scouts-about") {
        navigate("/scouts-about");
    }
    if (pageTitle == "scouts-leaders") {
        navigate("/scouts-leaders");
    }
    if (pageTitle == "scouts-badges") {
        navigate("/scouts-badges");
    }
}
