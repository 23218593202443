import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const newEventBtn = {
    textAlign: "right"
};

export const formTitles = {
    fontSize: "14px"
};

export const cardBody = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const topRow = {
    paddingTop: "20px"
};

export const eventsBox = {
    height: "500px",
    overflow: "auto",
    cursor: "pointer"
};
