import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import CalendarView from "./files/calendarView";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/" element={<CalendarView />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
