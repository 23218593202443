import * as Colors from "../../../../globalStyles/environmentColors";

export const menuButton = {
    height: "40px",
    border: `4px solid ${Colors.secondary}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    transition: "all 0.5s ease-out",
    cursor: "pointer"
};

export const menu = {
    overflow: "hidden",
    transition: "all 0.5s ease-out"
};

export const menuItem = {
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    borderRight: "solid 1px #7149C6",
    borderLeft: "solid 1px #7149C6",
    borderBottom: "solid 1px #7149C6",
    cursor: "pointer"
};

export const sideMenuItem = {
    height: "40px",
    border: `4px solid ${Colors.secondary}`,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    paddingLeft: "5px",
    fontWeight: "bold",
    margin: "5px",
    cursor: "pointer",
    transition: "all 0.5s ease-out"
};

export const sideMenu = {
    width: "175px"
};
