import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { Row, Col, Image } from "react-bootstrap";

//STYLES
import * as UserRouteSystemStyles from "./styles/userRouteSystem";

//ACTIONS
import * as UserActions from "../../store/actions/user";
import * as GS_generalSettings from "../../store/actions/globalSettings/GS_generalSettings";

import EnvironmentPodsMap from "../pods/environmentPodsMap";

//IMAGES
import WelshFlag from "../../public/images/welshFlag.png";

//USER SIDE
import BrowserNavBar from "../pods/special/navBar/browserNavBar";
// import MobileNavBar from "../pods/special/navBar/mobileNavBar";

//FROM ADMIN CORE SIDE
import AdminBanner from "../../administration/pods/adminBanner/adminBanner";
import PageViewer from "../../administration/pods/pageViewer/pageViewer";
import PodViewer from "../../administration/pods/wholeSite/podViewer";
import FrontEndMainContact from "../../administration/pods/contacts/files/frontEndMainContact";
import SiteOffline from "../../administration/pods/siteOffline/files/siteOffline";

import MembershipExtra from "../pods/membershipExtra/mainFiles/frontEnd/podRouter";

function UserRouteSystem() {
    const user = useSelector((state) => state.user);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isOffline, setIsOffline] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        onOpen();
    }, []);

    function onOpen() {
        Axios.post("/pods/userRouter/getOpeningData")
            .then((res) => {
                const data = res.data;
                console.log(data);
                dispatch(GS_generalSettings.UpdateMainContactPageVisible(data.settings.mainContactPage.value == "true"));
                dispatch(GS_generalSettings.UpdateSiteOffline(data.settings.siteOffline.value == "true"));
                dispatch(GS_generalSettings.UpdateSiteOfflineMessage(data.settings.siteOffline.subValue));
                setIsOffline(data.settings.siteOffline.value == "true");

                if (user.auth && user.type !== "registered") {
                    dispatch(UserActions.UpdateAdminSignedIn(true));
                }

                //MEMBERSHIP EXTRA POD
                if (user.requestedPassword) {
                    navigate("/membership/changePassword");
                }

                setIsLoaded(true);
            })
            .catch((err) => console.log(err));
    }

    function offlineUserCheck(role) {
        if (role == "modifier" || role == "registered" || role == "") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            {isLoaded && (
                <div>
                    {isOffline && !offlineUserCheck(user.type) ? (
                        <div>
                            <SiteOffline />
                        </div>
                    ) : (
                        <div style={UserRouteSystemStyles.body}>
                            {user.adminSignedIn ? <AdminBanner /> : null}
                            {/* {isBrowser ? <BrowserNavBar /> : <MobileNavBar />} */}
                            <BrowserNavBar />
                            <Row>
                                <Col sm={8}></Col>
                                <Col sm={2} style={UserRouteSystemStyles.welshFlagContainer}>
                                    <Image style={UserRouteSystemStyles.welshFlagPic} src={WelshFlag} />
                                </Col>
                                <Col sm={2} style={UserRouteSystemStyles.rightEdgeTop}></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col sm={2}></Col>
                                        <Col style={UserRouteSystemStyles.mainBody}>
                                            <Routes>
                                                <Route path="/" exact element={<PageViewer pageID={0} />} />
                                                <Route path="/beavers-about" element={<PageViewer pageID={7} />} />
                                                <Route path="/beavers-leaders" element={<PageViewer pageID={8} />} />
                                                <Route path="/beavers-badges" element={<PageViewer pageID={9} />} />
                                                <Route path="/cubs-about" element={<PageViewer pageID={10} />} />
                                                <Route path="/cubs-leaders" element={<PageViewer pageID={11} />} />
                                                <Route path="/cubs-badges" element={<PageViewer pageID={12} />} />
                                                <Route path="/scouts-about" element={<PageViewer pageID={13} />} />
                                                <Route path="/scouts-leaders" element={<PageViewer pageID={14} />} />
                                                <Route path="/scouts-badges" element={<PageViewer pageID={15} />} />
                                                <Route path="/fund-raising" element={<PageViewer pageID={1} />} />
                                                <Route path="/about-us" element={<PageViewer pageID={2} />} />
                                                <Route path="/events" element={<PageViewer pageID={3} />} />
                                                <Route path="/image-gallery" element={<PageViewer pageID={4} />} />
                                                <Route path="/hut-availability" element={<EnvironmentPodsMap podCode="CPOD_CALENDAR_FREND" />} />
                                                <Route path="/downloads" element={<PageViewer pageID={5} />} />
                                                <Route path="/trevor" element={<PageViewer pageID={6} />} />
                                                <Route path="/contact-us" element={<FrontEndMainContact />} />

                                                {/* <Route path="/appDevelopment" element={<EnvironmentPodsMap podCode="CPOD_APPREQUESTQUOTE_FREND" />} />
                                                <Route path="/webDevelopment" element={<EnvironmentPodsMap podCode="CPOD_WEBSITEQUOTE_FREND" />} />
                                                <Route path="/membership/*" element={<MembershipExtra />} /> */}
                                            </Routes>
                                        </Col>
                                        <Col sm={2} style={UserRouteSystemStyles.rightEdgeBottom}></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default UserRouteSystem;
