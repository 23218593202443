import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

function DetailsView(props) {
    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.event.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col>
                                <strong>Description:</strong> <br /> {props.event.description}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <strong>Times:</strong> <br />{" "}
                                {props.event.timeType == "allDay" ? "All Day" : `${props.event.startTime} - ${props.event.endTime}`}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <strong>Recurrence:</strong> <br /> {props.event.repeatType == "single" ? "This is a single booking." : "--"}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DetailsView;
